import React, { useState, useEffect } from 'react';

const EditTimeDialog = ({ isOpen, onClose, onSave, initialData }) => {
  const [editData, setEditData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    pause: ''
  });
 
  const [errors, setErrors] = useState({});
 
  useEffect(() => {
    if (initialData) {
      setEditData(initialData);
      setErrors({});
    }
  }, [initialData]);
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };
 
  const validateForm = () => {
    const newErrors = {};
    
    if (!editData.date) {
      newErrors.date = 'Datum ist erforderlich';
    }
    if (!editData.startTime) {
      newErrors.startTime = 'Startzeit ist erforderlich';
    }
    if (!editData.endTime) {
      newErrors.endTime = 'Endzeit ist erforderlich';
    }
    if (!editData.pause) {
      newErrors.pause = 'Pause ist erforderlich';
    }
 
    if (editData.startTime && editData.endTime) {
      const start = new Date(`1970-01-01T${editData.startTime}`);
      const end = new Date(`1970-01-01T${editData.endTime}`);
      if (end <= start) {
        newErrors.endTime = 'Endzeit muss nach Startzeit liegen';
      }
    }
 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 
  const handleSave = (e) => {
    e.preventDefault();
 
    if (!editData.date || !editData.startTime || !editData.endTime || !editData.pause) {
      setErrors({
        date: !editData.date ? 'Datum ist erforderlich' : '',
        startTime: !editData.startTime ? 'Startzeit ist erforderlich' : '',
        endTime: !editData.endTime ? 'Endzeit ist erforderlich' : '',
        pause: !editData.pause ? 'Pause ist erforderlich' : ''
      });
      return;
    }
 
    if (validateForm()) {
      onSave(editData);
      onClose();
    }
  };
 
  if (!isOpen) return null;
 
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <form onSubmit={handleSave} className="bg-white rounded-lg shadow-lg w-[500px] p-6">
        <h2 className="text-xl font-bold mb-4">Zeit bearbeiten</h2>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Datum</label>
          <input
            type="date"
            name="date"
            value={editData.date}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.date ? 'border-red-500' : 'border-gray-300'} rounded`}
            required
          />
          {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Startzeit</label>
          <input
            type="time"
            name="startTime"
            value={editData.startTime}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.startTime ? 'border-red-500' : 'border-gray-300'} rounded`}
            required
          />
          {errors.startTime && <p className="text-red-500 text-sm mt-1">{errors.startTime}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Endzeit</label>
          <input
            type="time"
            name="endTime"
            value={editData.endTime}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.endTime ? 'border-red-500' : 'border-gray-300'} rounded`}
            required
          />
          {errors.endTime && <p className="text-red-500 text-sm mt-1">{errors.endTime}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Pause</label>
          <input
            type="time"
            name="pause"
            value={editData.pause}
            onChange={handleInputChange}
            className={`w-full p-2 border ${errors.pause ? 'border-red-500' : 'border-gray-300'} rounded`}
            required
          />
          {errors.pause && <p className="text-red-500 text-sm mt-1">{errors.pause}</p>}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={!editData.date || !editData.startTime || !editData.endTime || !editData.pause}
            className={`bg-[#0000FF] text-white py-2 px-4 rounded-lg mr-2 
              ${(!editData.date || !editData.startTime || !editData.endTime || !editData.pause) ? 
                'opacity-50 cursor-not-allowed' : ''}`}
          >
            Speichern
          </button>
          <button
            type="button"
            className="text-[#0000FF] py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </form>
    </div>
  );
};
 
export default EditTimeDialog;