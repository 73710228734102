import React, { useState } from 'react';
import * as Realm from 'realm-web';

const EditEmployeeDetails = ({ employee, onBack, user }) => {
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const [jobTitle, setJobTitle] = useState(employee.jobTitle);
  const [employeeId, setEmployeeId] = useState(employee.employeeId);
  const [isAdmin, setIsAdmin] = useState(employee.isAdmin);
  const [isSuperAdmin, setIsSuperAdmin] = useState(employee.isSuperAdmin || false);
  const [isBRAdmin, setIsBRAdmin] = useState(employee.isBRadmin || false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isUsernameChange, setIsUsernameChange] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [confirmNewUsername, setConfirmNewUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [arbeitszeitmodell, setArbeitszeitmodell] = useState(employee?.timeModel && typeof employee.timeModel === 'string' ? employee.timeModel : 'Keine Angaben');
  const [hoursMonth, setHoursMonth] = useState(employee?.hours_month && typeof employee.hours_month === 'string' ? employee.hours_month : '0');
  const [hoursWeek, setHoursWeek] = useState(employee?.hours_week && typeof employee.hours_week === 'string' ? employee.hours_week : '0');
  const [holidayValue, setHolidayValue] = useState(employee?.holidayValue && typeof employee.holidayValue === 'string' ? employee.holidayValue : '0');
  const [isTrustedEmployee, setIsTrustedEmployee] = useState(employee?.isTrustedEmployee || false);
  const token = localStorage.getItem('accessToken');

  const app = new Realm.App({ id: process.env.REACT_APP_MONGO_APP });

  const handleAdminCheckboxChange = () => {
    setIsAdmin(true);
    setIsSuperAdmin(false);
  };

  const handleSuperAdminCheckboxChange = () => {
    setIsAdmin(true);
    setIsSuperAdmin(true);
  };

  const handleBRadminCheckboxChange = () => {
    setIsAdmin(false);
    setIsSuperAdmin(false);
    setIsBRAdmin(true)
  };

  const handleNoAdminCheckboxChange = () => {
    setIsAdmin(false);
    setIsSuperAdmin(false);
  };

  const handleTrustedEmployeeChange = (value) => {
    setIsTrustedEmployee(value);
  };

  const handleSave = async () => {
    const url = 'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeEmployee';

    const requestBody = {
      token,
      email: employee.email,
      firstName,
      lastName,
      jobTitle,
      employeeId,
      isAdmin,
      isSuperAdmin,
      isBRAdmin,
      arbeitszeitmodell,
      hoursMonth,
      hoursWeek,
      holidayValue,
      isTrustedEmployee
    };

    if (isUsernameChange && newUsername === confirmNewUsername) {
      requestBody.newEmail = newUsername;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const result = await response.json();

      if (result.success) {
        const updatedEmployee = { 
          ...employee, 
          firstName, 
          lastName, 
          jobTitle, 
          employeeId, 
          isAdmin, 
          isSuperAdmin, 
          timeModel: arbeitszeitmodell,
          hours_month: hoursMonth,
          hours_week: hoursWeek,
          email: newUsername || employee.email,
          holidayValue,
          isTrustedEmployee
        };
        onBack(updatedEmployee);
      } else {
        setErrorMessage(result.error || 'Failed to update employee details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating employee details');
    }
  };

  const handleDelete = async () => {
    const url = 'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/deleteEmployee';

    const requestBody = {
      token,
      email: employee.email
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const result = await response.json();

      if (result.success) {
        onBack(null, true);
      } else {
        setErrorMessage(result.error || 'Failed to delete employee');
      }
    } catch (error) {
      setErrorMessage('An error occurred while deleting employee');
    }
  };

  return (
    <div>
      <header className="flex justify-between items-center mb-[32px]">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Mitarbeiter bearbeiten</h1>
          <p className="text-sm text-gray-600">{employee.firstName} {employee.lastName} &gt; Bearbeiten</p>
        </div>
        {user?.isSuperAdmin && (
          <button 
            onClick={handleDelete} 
            className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-md"
          >
            Mitarbeiter entfernen
          </button>
        )}
      </header>

      <div>
        <h2 className="text-xl font-bold text-gray-800 mb-4">Persönliche Informationen</h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 mb-1">Vorname</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Nachname</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Jobtitel</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={jobTitle} 
              onChange={(e) => setJobTitle(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Mitarbeiter-ID</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={employeeId} 
              onChange={(e) => setEmployeeId(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Arbeitszeitmodell</label>
            <select 
              className="bg-white p-2 rounded-md w-full" 
              value={arbeitszeitmodell} 
              onChange={(e) => setArbeitszeitmodell(e.target.value)}
            >
              <option value="Vollzeit">Vollzeit</option>
              <option value="Teilzeit">Teilzeit</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Monatsarbeitsstunden</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              defaultValue={hoursMonth == typeof String ? employee?.hours_month : '0'}
              value={hoursMonth} 
              onChange={(e) => setHoursMonth(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Wochenarbeitsstunden</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={hoursWeek} 
              onChange={(e) => setHoursWeek(e.target.value)} 
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Urlaubstage</label>
            <input 
              className="bg-white p-2 rounded-md w-full" 
              value={holidayValue} 
              onChange={(e) => setHolidayValue(e.target.value)} 
            />
          </div>
          <div>
            <h2 className="text-xl font-bold text-gray-800 mb-4">Karrenzeit</h2>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="karrenzeit" 
                value="no" 
                className="mr-2" 
                checked={!isTrustedEmployee}
                onChange={() => handleTrustedEmployeeChange(false)}
              />
              <label className="text-gray-700">Ja</label>
            </div>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="karrenzeit" 
                value="yes" 
                className="mr-2" 
                checked={isTrustedEmployee}
                onChange={() => handleTrustedEmployeeChange(true)}
              />
              <label className="text-gray-700">Nein</label>
            </div>
          </div>
        </div>

        {
          user.isSuperAdmin &&
          <div className="mt-6">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Adminrechte bearbeiten?</h2>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="adminRights" 
                value="admin" 
                className="mr-2" 
                checked={isAdmin && !isSuperAdmin}
                onChange={handleAdminCheckboxChange}
              />
              <label className="text-gray-700">Ja, als Admin anlegen</label>
            </div>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="adminRights" 
                value="superAdmin" 
                className="mr-2" 
                checked={isSuperAdmin}
                onChange={handleSuperAdminCheckboxChange}
              />
              <label className="text-gray-700">Ja, als Super Admin anlegen</label>
            </div>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="adminRights" 
                value="BRadmin" 
                className="mr-2" 
                checked={isBRAdmin}
                onChange={handleBRadminCheckboxChange}
              />
              <label className="text-gray-700">Ja, als Betriebsrat anlegen</label>
            </div>
            <div className="flex items-center mb-4">
              <input 
                type="radio" 
                name="adminRights" 
                value="none" 
                className="mr-2" 
                checked={!isAdmin && !isSuperAdmin}
                onChange={handleNoAdminCheckboxChange}
              />
              <label className="text-gray-700">Nein, nicht als Admin anlegen</label>
            </div>
          </div>
        }

        {errorMessage && (
          <div className="bg-red-200 text-red-700 p-4 rounded-md mb-4">
            {errorMessage}
          </div>
        )}

        <button 
          onClick={async () => {
            await app.emailPasswordAuth.sendResetPasswordEmail({email: employee.email})
          }} 
          className="bg-[#0000FF] text-white py-2 px-4 rounded-md mt-[35px] font-[500]"
        >
          Email zum Passwort Zurücksetzen an den Mitarbeiter senden
        </button>

        <div className="flex mt-[35px]">
          <button 
            onClick={handleSave} 
            className="bg-[#0000FF] text-white py-2 px-4 rounded-md mr-[12px] font-[500]"
          >
            Speichern
          </button>
          <button 
            onClick={() => onBack(employee)} 
            className="text-[#0000FF] py-2 px-4 rounded-md font-[500]"
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeeDetails;